import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://e3476b14780744ea881cd7700234fcaa@sentry.d10multimedia.com/5',
  autoSessionTracking: false
});
